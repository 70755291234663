const firebaseConfig = {
  apiKey: 'AIzaSyAQGxl_5msjLo5una-51UjRvf2KZp612lE',
  authDomain: 'marcus-dev-env.firebaseapp.com',
  databaseURL: 'https://marcus-dev-env.firebaseio.com',
  projectId: 'marcus-dev-env',
  storageBucket: 'marcus-dev-env.appspot.com',
  messagingSenderId: '960681537030',
  appId: '1:960681537030:web:93d3d4aaefdc0e68465d87',
  measurementId: 'G-786N1MKG02',
};

export default firebaseConfig;
